import {Controller} from "@hotwired/stimulus"
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper";
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request";
import consumer from '../channels/consumer';

// Connects to data-controller="barcode-scan"
export default class extends Controller {
  static targets = [
    "input",
    "overlay",
    "stockCard",
    "quantity",
    "qrScanned",
    "templateElement",
    "quantityCheckedIn",
    "consumptionPoint",
    "emptyBody",
    "turboFrameTag"
  ];

  static values = {
    url: String, // Scan action placed in barcode_formats_controller.rb
    turboUrl: String, // Turbo request, tailored to the needs of the form
    basedOnStockIdLotExpire: Boolean, // Specifies the type of form
    nestedTable: Boolean, // Table can grow, which means that elements can be added, elements can be removed.
    turboFrameOnScan: Boolean, // Indicates whether to perform a Turbo frame request when a scan occurs
    turboFrameOnScanUrl: String, // Turbo Frame URL
    serialScanner: Boolean,// Child js of barcode
  };

  connect() {
    this.scannedBarcodeData = {}; // Per user
    this.channelClass = "BarcodeOperationsChannel";
    this.channelPrefix = "BarcodeOperations";
    this.clearAndToggleInput();
    this.createSubscription();
    this.barcodeTurboRequestInProgress = false;
    this.trackBarcodeTurboStreamRequests();
  }

  clearAndToggleInput() {
    if (this.hasInputTarget) {
      this.inputTarget.value = '';
      this.inputTarget.classList.toggle('hidden');
    }
  }

  createSubscription() {
    this.subscription = consumer.subscriptions.create(
      {channel: this.channelClass, channel_id: this.channelId()}, {
        received: (data) => {
          data = JSON.parse(data.message);
          this.setValue(data.barcode_input, data);
          this.tryManipulateDom(data);
        }
      }
    )
  }

  trackBarcodeTurboStreamRequests() {
    // Add event listener for the start of the request
    document.addEventListener('barcodeTurboRequest:start', () => {
      this.barcodeTurboRequestInProgress = true;
    });

    // Add event listener for the end of the request
    document.addEventListener('barcodeTurboRequest:end', (event) => {
      this.barcodeTurboRequestInProgress = !event.detail.status;
    });
  }

  scan() {
    const barcodeInput = this.inputTarget.value;
    const data = this.getValue(barcodeInput);
    if (data) {
      this.tryManipulateDom(data);
    } else {
      this.processBarcode(barcodeInput);
    }
    this.inputTarget.value = '';
  }

  getValue(key) {
    return this.scannedBarcodeData[key];
  }

  setValue(key, value) {
    this.scannedBarcodeData[key] = value;
  }

  tryManipulateDom(data) {
    if (!this.barcodeTurboRequestInProgress) {
      this.manipulateDomByData(data);
    } else {
      setTimeout(() => {
        this.tryManipulateDom(data);
      }, 500); // Just add rate interval between first end the rests.
    }
  }

  manipulateDomByData(data) {
    if (data.error) {
      this.notify(data.error, 'error');
    } else {
      if (this.nestedTableValue) {
        this.sendNestedTableTurboRequest(data);
      } else {
        this.sendStaticTableTurboRequest(data);
      }
      if (this.turboFrameOnScanValue) {
        this.initiateTurboFrame(data);
      }
    }
  }

  channelId() {
    return this.inputTarget.dataset.channelId;
  }

  processBarcode(barcodeInput) {
    const barcodeScanUrl = buildUrl(
      this.urlValue,
      {
        barcode: barcodeInput,
        channel_id: this.channelId(),
        channel_prefix: this.channelPrefix
      });

    this.enqueueInput(barcodeScanUrl);
  }

  enqueueInput(barcodeScanUrl) {
    fetch(barcodeScanUrl)
      .then(response => Promise.all([response.ok, response.json()]))
      .then(([ok, message]) => {
        if (!ok) {
          this.notify(message.error, 'error');
        }
      });
  }

  notify(message, status) {
    $notification({text: message, variant: status, duration: 1000});
  }

  sendStaticTableTurboRequest(data) {
    let targetStockCardQuantity = `${data.stock_card_composite_identifier}_quantity`;
    let targetStockCardQuantityCheckedIn = `${data.stock_card_primary_identifier}_quantity_checked_in`;
    let quantity;
    let totalCheckedInQuantity;
    let barcodeScanned = true;

    if (this.basedOnStockIdLotExpireValue) {
      try {
        quantity = this.quantityTargets.find(el => el.id === targetStockCardQuantity).value;
      } catch (typeError) {
        barcodeScanned = "";
      }
    } else {
      quantity = this.quantityTarget.value;
    }

    let params = {
      stock_card_id: data.stock_card_id,
      lot: data.lot,
      expiry_date: data.expiry_date,
      quantity: Number(quantity) + 1,
      barcode_scanned: barcodeScanned
    };

    if (this.hasQuantityCheckedInTarget) {
      totalCheckedInQuantity = this.calculateTotalCheckedInQuantity(data.stock_card_id);
      params["total_quantity_checked_in"] = totalCheckedInQuantity;
    }

    const headers = {
      'Turbo-Stock-Card-Quantity-Target': targetStockCardQuantity,
      'Turbo-Stock-Card-Quantity-CheckedIn-Target': targetStockCardQuantityCheckedIn
    };

    if (this.hasConsumptionPointTarget) {
      params["consumption_point_id"] = this.consumptionPointTarget.tomselect.getValue();
    }

    const turboUrl = buildUrl(this.turboUrlValue, params);
    const turboHeaders = buildHeaders(headers);
    const request = buildRequest(turboUrl, turboHeaders);
    sendTurboStreamRequest(request, 'barcodeTurboRequest:end', this.identifier);
  }

  // Direct Transfer, Stock Entry
  // This type of table can grow, which means that elements can be added, elements can be removed.
  sendNestedTableTurboRequest(data) {
    let stockCardCompositeIdentifier = data.stock_card_composite_identifier;
    let stockCardPrimaryIdentifier = data.stock_card_primary_identifier;
    let targetStockCard = this.basedOnStockIdLotExpireValue ? stockCardCompositeIdentifier : stockCardPrimaryIdentifier;
    let targetStockCardQuantityCheckedIn = `${targetStockCard}_quantity_checked_in`;
    let targetStockCardQuantity = `${stockCardCompositeIdentifier}_quantity`;
    let lotTracking = data.lot_tracking;

    let stockCardExistsInDocument;
    let turboAction = 'replace';
    let quantity = 0;
    let totalCheckedInQuantity = 1;
    let incrementQuantity;

    if (this.hasSerialScannerValue) {
      incrementQuantity = this.serialScannerValue;
    } else {
      incrementQuantity = true;
    }

    // Does the document include any stock cards
    if (this.hasStockCardTarget) {
      stockCardExistsInDocument = this.stockCardTargets.some(el => el.id === targetStockCard);

      // Is the stock card found with the new qr on the page
      if (stockCardExistsInDocument) {
        // Whether document stock cards identifier is id or id_lot_expire combination
        // If identifier is id (stockCardPrimaryIdentifier) just update quantity and calculate sum

        // Just update the quantity targets
        totalCheckedInQuantity = this.calculateTotalCheckedInQuantity(data.stock_card_id);

        if (incrementQuantity) {
          this.updateNestedTableStockCardQuantity(
            totalCheckedInQuantity,
            targetStockCardQuantity,
            targetStockCardQuantityCheckedIn,
            lotTracking
          );
        }

        return;
      } else {
        // If there is no element in document body -even template-, prepend it to the top of table.
        if (this.hasEmptyBodyTarget) {
          targetStockCard = this.emptyBodyTarget.id;
          turboAction = 'prepend';
        } else {
          targetStockCard = this.stockCardTargets[0].id;
          turboAction = 'before';
        }
      }
    } else if (this.hasTemplateElementTarget) {
      // First qr scan on the page
      targetStockCard = this.templateElementTarget.id;
    } else {
      // First qr scan empty body page
      turboAction = 'prepend';
      targetStockCard = this.emptyBodyTarget.id;
    }

    const params = {
      stock_card_id: data.stock_card_id,
      lot: data.lot,
      expiry_date: data.expiry_date,
      child_index: Date.now(),
      quantity: quantity,
      total_quantity_checked_in: totalCheckedInQuantity,
      barcode: data.barcode
    };


    if (this.hasSerialScannerValue) {
      params["serial_scanned"] = this.serialScannerValue;
    }

    const headers = {
      'Turbo-Action': turboAction,
      'Turbo-Stock-Card-Target': targetStockCard,
      'Turbo-Stock-Card-Quantity-Target': targetStockCardQuantity,
      'Turbo-Stock-Card-Quantity-CheckedIn-Target': targetStockCardQuantityCheckedIn
    };


    const url = buildUrl(this.turboUrlValue, params);
    const turboHeaders = buildHeaders(headers);

    const request = buildRequest(url, turboHeaders);
    sendTurboStreamRequest(request, 'barcodeTurboRequest:end', this.identifier);
  }

  calculateTotalCheckedInQuantity(stockCardId) {
    return this.quantityTargets.filter(el => el.id.includes(stockCardId))
      .reduce((total, target) => total + (target.value ? parseInt(target.value) : 0), 1);
  }

  updateNestedTableStockCardQuantity(
    totalCheckedInQuantity,
    targetStockCardQuantity,
    targetStockCardQuantityCheckedIn,
    lotTracking
  ) {
    const stockCardQuantity = document.getElementById(`${targetStockCardQuantity}`);
    if (stockCardQuantity) {
      stockCardQuantity.value = Number(stockCardQuantity.value) + 1;
      stockCardQuantity.dispatchEvent(new Event('input'));
    } else if (lotTracking) {
      this.notify(this.inputTarget.dataset.errorText, 'error');
      return;
    }

    const stockCardQuantityCheckedIn = document.getElementById(`${targetStockCardQuantityCheckedIn}`);
    if (stockCardQuantityCheckedIn) {
      this.updateNestedTableStockCardQuantityCheckedIn(
        totalCheckedInQuantity,
        stockCardQuantityCheckedIn
      )
    }

    this.notify(this.inputTarget.dataset.successText, 'success');
  }

  updateNestedTableStockCardQuantityCheckedIn(totalCheckedInQuantity, stockCardQuantityCheckedIn) {
    if (this.basedOnStockIdLotExpireValue) {
      stockCardQuantityCheckedIn.value = Number(stockCardQuantityCheckedIn.value) + 1;
    } else {
      stockCardQuantityCheckedIn.value = totalCheckedInQuantity;
    }
    stockCardQuantityCheckedIn.dispatchEvent(new Event('input'));
  }

  focusElements() {
    this.inputTarget.value = '';
    this.inputTarget.focus();
  }

  toggleOverlayAndAddEvents() {
    this.clearAndToggleInput();
    this.inputTarget.focus();
    this.overlayTarget.classList.toggle('hidden');
    this.addInputEvent();
  }

  blockSubmit(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.scan();
    }
  }

  addInputEvent() {
    this.inputTarget.addEventListener('focusout', this.handleFocusOut.bind(this));
  }

  handleFocusOut(event) {
    if (this.overlayTarget.classList.contains('hidden')) {
      this.inputTarget.removeEventListener('focusout', this.handleFocusOut);
    } else {
      event.preventDefault();
      this.inputTarget.focus();
    }
  }

  initiateTurboFrame(data) {
    let targetStockCardQuantity = `${data.stock_card_composite_identifier}_quantity`;
    let quantity;

    if (this.basedOnStockIdLotExpireValue) {
      quantity = this.quantityTargets.find(el => el.id === targetStockCardQuantity)?.value;
    } else {
      quantity = this.quantityTarget.value;
    }
    
    const params = {
      stock_card_id: data.stock_card_id,
      lot: data.lot,
      expire_date: data.expiry_date,
      quantity: quantity
    };

    this.turboFrameTagTarget.src = buildUrl(this.turboFrameOnScanUrlValue, params);
    this.toggleOverlayAndAddEvents();
  }

  disconnect() {
    // Cleanup when this controller is disconnected
    this.scannedBarcodeData = {};
    if (this.subscription) {
      consumer.subscriptions.remove(this.subscription)
    }
  }
}

