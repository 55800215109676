import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {

  static values = {
    type: String,
    dataset: Array
  };

  initialize() {
    this.chart = null;
  }

  connect() {
    this[this.typeValue]();
  }

  generateEfficiencyByTestGroupChart() {
    const data = this.datasetValue;
    const labels = data.map(item => item.test_group);
    const efficiencyRate = data.map(item => item.efficiency_rate);
  
    const options = {
      series: [{
        data: efficiencyRate,
        name: this.element.dataset.efficiencyRate
      }],
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: true,
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(1) + " %";
        }
      },
      xaxis: {
        categories: labels,
        labels: {
          formatter: function(val) {
            return val.toFixed(1) + " %";
          }
        },
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function(val) {
            return val;
          }
        },
        y: {
          formatter: function(val) {
            return val.toFixed(1) + " %";
          }
        }
      },
    };
  
    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  generateEfficiencyByDeviationChart() {
    const data = this.datasetValue;
    const categories = data.map(group => group.month);
    const values = data.map(group => group.efficiency_rate);

    var options = {
      series: [{
        name:  this.element.dataset.efficiencyRate,
        data: values
      }],
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(1) + " %";
        }
      },
      stroke: {
        curve: 'straight'
      },
      yaxis: {
        labels: {
          formatter: function(val) {
            return val.toFixed(1) + " %";
          }
        },
      },
      xaxis: {
        categories: categories,
      },
    };

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy(); 
      this.chart = null;
    }
  }
}
