/**
 Sends a Turbo Stream request and renders the response.
 @param {Request} request - The request to send.
 @param {string} eventName - The name of the custom event to dispatch after rendering the Turbo Stream message. Optional.
 @param {string} referrer - Caller of the request
 @returns {Promise} A promise that resolves when the Turbo Stream message is rendered.
 */
export function sendTurboStreamRequest(request, eventName, referrer) {
  if (referrer === 'barcode-scan') {
    document.dispatchEvent(new CustomEvent('barcodeTurboRequest:start'));
  }

  return fetch(request)
    .then(async (response) => {
      const html = await response.text();

      Turbo.renderStreamMessage(html);

      if (eventName) {
        document.dispatchEvent(
          new CustomEvent(eventName, {
            detail: {
              request,
              status: response.status
            }
          })
        );
      }
    })
    .catch((error) => console.error("Error fetching Turbo Stream:", error));
}

// Example usage:
// import { sendTurboStreamRequest } from "../helpers/turbo_stream_helper"
// import { buildRequest, buildUrl, buildHeaders } from "../helpers/build_request"

// Send the turbo_stream_request

// const url = buildUrl(this.turboRequiredParamsValue.url, params);
// const request = buildRequest(url, buildHeaders());
// sendTurboStreamRequest(request, this.turboRequiredParamsValue.event);