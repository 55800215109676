import {Controller} from "@hotwired/stimulus"
import IMask from "imask";

// Connects to data-controller="input-mask"
export default class extends Controller {
  static targets = ["amount", "amountValue"];

  connect() {
    if (!this.mask && this.hasAmountTarget) {
      this.initializeMask()
    }
  }

  initializeMask() {
    this.mask = IMask(this.amountTarget, {
      mask: Number,
      scale: 2,
      thousandsSeparator: ".",
      min: 0
    })

    this.amountTarget.addEventListener('input', () => {
      if (this.hasAmountValueTarget) {
        this.amountValueTarget.value = this.mask.unmaskedValue.replace(".", ",");
      }
    })
  }
}
