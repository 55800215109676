import { animationState } from "./download_animation_handler"

export async function printPdf(event) {
  event.preventDefault();

  // animations start
  animationState(event, 'start');

  try {
    const response = await fetch(event.target.href);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    const printWindow = window.open(data.pdf_url, '_blank');

    if (printWindow) {
      printWindow.addEventListener('load', function() {
        printWindow.print();
      });
    } else {
      alert('Please disable pop-up blocker and try again.');
    }
  } catch (error) {
    console.error('Error:', error);
    alert('Error: PDF printing failed. Please try again.');
  } finally {
    // animations end
    animationState(event, 'finish');
  }
}
