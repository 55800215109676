import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-toggle"
export default class extends Controller {
  static values = {
    tableSearch: Boolean, // table search used with checkboxes
  };

  static targets = [
    "selectAll",
    "checkbox",
    "countSelected",
    "toggleable"
  ];

  connect() {
    this.updateAllParentCheckboxes();
    this.updateSelectAllState();
    this.insertSelectedCount();
    this.toggleToggleable();
  }

  toggle(event) {
    const clickedCheckbox = event.target;
    const parentId = clickedCheckbox.dataset.parentId;
    const isChildCheckbox = clickedCheckbox.dataset.selfToggle === "true";

    if (isChildCheckbox && parentId) {
      this.updateParentCheckbox(parentId);
    }
    else if (parentId && !isChildCheckbox) {
      this.updateChildCheckboxesForParent(parentId, clickedCheckbox.checked);
    }
    else if (!parentId && !isChildCheckbox) {
      // Select All
      this.updateStateForElements(this.getCheckboxes(), clickedCheckbox.checked);
    }

    this.updateSelectAllState();
    this.insertSelectedCount();
    this.toggleToggleable();
  }

  updateAllParentCheckboxes() {
    const uniqueParentIds = new Set();

    this.getCheckboxes().forEach((checkbox) => {
      if (checkbox.dataset.parentId) {
        uniqueParentIds.add(checkbox.dataset.parentId);
      }
    });

    uniqueParentIds.forEach((parentId) => {
      this.updateParentCheckbox(parentId);
    });
  }

  updateChildCheckboxesForParent(parentId, isSelected) {
    this.getCheckboxes().forEach((checkbox) => {
      if (checkbox.dataset.parentId === parentId) {
        checkbox.checked = isSelected;
        if (isSelected) {
          checkbox.setAttribute("checked", "checked");
        } else {
          checkbox.removeAttribute("checked");
        }
      }
    });
  }

  updateParentCheckbox(parentId) {
    const parentCheckbox = this.element.querySelector(
      `input[type="checkbox"][data-parent-id="${parentId}"]:not([data-self-toggle="true"])`
    );

    if (parentCheckbox) {
      const childCheckboxes = this.getCheckboxes().filter(
        (c) => c.dataset.parentId === parentId && c.dataset.selfToggle === "true"
      );
      const allChecked = childCheckboxes.every((c) => c.checked);

      parentCheckbox.checked = allChecked;
      if (allChecked) {
        parentCheckbox.setAttribute("checked", "checked");
      } else {
        parentCheckbox.removeAttribute("checked");
      }
    }
  }

  updateSelectAllState() {
    const totalCheckboxCount = this.getCheckboxes().length;
    const checkedCount = this.checkedBoxes().length;

    this.selectAllTargets.forEach((selectAll) => {
      const allSelected = (totalCheckboxCount === checkedCount && totalCheckboxCount > 0);
      selectAll.checked = allSelected;
      if (allSelected) {
        selectAll.setAttribute("checked", "checked");
      } else {
        selectAll.removeAttribute("checked");
      }
    });
  }

  updateStateForElements(elements, isSelected) {
    elements.forEach((element) => {
      element.checked = isSelected;
      if (isSelected) {
        element.setAttribute("checked", "checked");
      } else {
        element.removeAttribute("checked");
      }
    });
  }

  insertSelectedCount() {
    if (this.hasCountSelectedTarget) {
      this.countSelectedTarget.innerHTML = `${this.checkedBoxes().filter((ch) => ch.dataset.selfToggle === "true").length}`;
    }
  }

  toggleToggleable() {
    // If any checkbox selected then active the toggleable element. 
    // By default toggleable target is set disabled.
    addEventListener("turbo:before-stream-render", ((event) => {
      if (this.hasToggleableTarget) {
        this.toggleableTarget.disabled = true;
      }
    }));

    if (this.hasToggleableTarget) {
      this.toggleableTarget.disabled = (this.checkedBoxes().length === 0);
    }
  }

  getCheckboxes() {
    return this.checkboxTargets.filter((checkbox) => {
      if (this.tableSearchValue) {
        const tableSearchRow = checkbox.closest('[data-table-search-target="row"]');
        if (tableSearchRow) {
          const style = window.getComputedStyle(tableSearchRow);
          return style.display !== 'none';
        }
        return true;
      } else {
        return true;
      }
    });
  }

  checkedBoxes() {
    return this.getCheckboxes().filter(ch => ch.checked);
  }
}
