import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="customer"
export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    this.addCheckboxListeners();
  }

  addCheckboxListeners() {
    const checkboxes = this.element.querySelectorAll('[data-customer-target="checkbox"]');

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", this.togglePrimaryCheckbox.bind(this)); 
    });
  }

  togglePrimaryCheckbox(event) {
    const selectedCheckbox = event.target;

    if (selectedCheckbox.checked) {
      this.deselectOthers(selectedCheckbox);
    }
  }

  deselectOthers(selectedCheckbox) {
    const checkboxes = this.element.querySelectorAll('[data-customer-target="checkbox"]');

    checkboxes.forEach((checkbox) => {
      if (checkbox !== selectedCheckbox) {
        checkbox.checked = false;
      }
    });
  }
}