import {Controller} from "@hotwired/stimulus"
import {buildUrl} from "../helpers/build_request"

// Connects to data-controller="inventory-adequacy-analysis"
export default class extends Controller {
  static targets = ["form", "filterButton"]

  connect() {
    super.connect();
  }

  formTargetConnected() {
    const submitters = this.filterButtonTargets;
    const form = this.formTarget;
    submitters.forEach(submitter => {
      submitter.addEventListener('click', (event) => {
        const submitWithParams = submitter.dataset.submitWithParams
          ? JSON.parse(submitter.dataset.submitWithParams) : {};
        let filterFormParams = new URLSearchParams(new FormData(form));
        Object.entries(submitWithParams).forEach(([key, value]) => {
          filterFormParams.append(key, value);
        });

        let url = buildUrl(submitter.dataset.url, {})
        url.search = filterFormParams;
        this.turboVisitReplace(url, submitter.dataset.frameId);
      })
    });
  }

  turboVisitReplace(url, frameId) {
    Turbo.visit(url, {
        action: "replace",
        frame: frameId
      }
    )
  }

  disconnect() {
    super.disconnect();
  }
};
