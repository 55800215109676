import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="stock-card-unit"
export default class extends Controller {
  static targets = ["unit"];

  toggleVisibility() {
    this.unitTarget.classList.toggle('unselectable');
    this.unitTarget.tomselect.wrapper.classList.toggle('unselectable');
  }
}
