import {Controller} from "@hotwired/stimulus"
import BarcodeScanController from "./barcode_scan_controller";
import consumer from "../channels/consumer";
import {buildUrl} from "../helpers/build_request";

// Connects to data-controller="serial-scan"
export default class extends BarcodeScanController {

  connect() {
    this.scannedBarcodeData = {}; // Per user and per controller
    this.channelClass = "SerialBarcodeOperationsChannel";
    this.channelPrefix = "SerialBarcodeOperations";
    this.clearAndToggleInput();
    this.createSubscription();
    this.barcodeTurboRequestInProgress = false;
    this.trackBarcodeTurboStreamRequests();
  }

  createSubscription() {
    this.subscription = consumer.subscriptions.create(
      {channel: this.channelClass, channel_id: this.channelId()}, {
        received: (data) => {
          data = JSON.parse(data.message);
          this.setValue(data.barcode_input, data);
          this.tryManipulateDom(data);
        }
      })
  }

  processBarcode(barcodeInput) {
    const barcodeScanUrl = buildUrl(
      this.urlValue,
      {
        barcode: barcodeInput,
        channel_id: this.channelId(),
        channel_prefix: this.channelPrefix
      });

    this.enqueueInput(barcodeScanUrl);
  }
}
