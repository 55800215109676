import {Controller} from "@hotwired/stimulus"
import Tabulator from "../../extensions/TabulatorExtended";

// Connects to data-controller="tabulator--demand_plans"
export default class extends Controller {
  static values = {
    url: String,
    columns: Array,
    locale: String,
    supplierOrderFormUrl: String,
  }

  static targets = ['table', 'form', 'btn', 'total'];

  connect() {
    this.loadTabulator();
    this.btnTarget.addEventListener('click', () => {
      this.fillAndSubmitForm();
    });
  }

  loadTabulator() {
    this.tabulator = new Tabulator(this.tableTarget, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      pagination: true,
      paginationMode: 'local',
      paginationSize: 50,
      // Disable row selection
      selectableRows: false,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: 'local',
      columns: this.columnsValue.map(column => {
        if (column.field === "total_quantity") {
          return {
            ...column,
            formatter: this.totalQuantityFormatter.bind(this),
          };
        }
        return column;
      }),
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
      rowFormatter: this.nestedTableFormatter.bind(this),
    });
  }


  nestedTableFormatter(row) {
    const holderEl = document.createElement("div");
    holderEl.classList.add("w-2/3", "p-4");
    holderEl.insertAdjacentHTML("beforeend", row.getData().partial);
    this.formTarget.insertAdjacentHTML('beforeend', row.getData().partial);
    const hasTbody = holderEl.querySelector('tbody') !== null
    if (hasTbody) {
      row.getElement().appendChild(holderEl);
      this.trackPartialInputs(holderEl, row);
    }
  }

  trackPartialInputs(holderEl, row) {
    const inputsAndSelects = holderEl.querySelectorAll('input[name*="demand_quantity"], select[name*="supplier_id"]');
    const multiplier = row.getData().multiplier;
    const totalOfOrdersTarget = row.getData().total_of_orders_target;
    inputsAndSelects.forEach((element) => {
      const fieldName = element.getAttribute('name');
      element.addEventListener('input', (event) => {
        const totalOfOrderTarget = this.totalTargets.find(el => el.id === totalOfOrdersTarget);
        const newValue = element.value;
        if (fieldName) {
          const el = this.formTarget.querySelector(`[name="${fieldName}"]`);
          if (!fieldName.includes('[supplier_id]'))
          {
            totalOfOrderTarget.innerText = String(
              Number(totalOfOrderTarget.innerText) + Number(newValue) - Number(el.value / multiplier)
            );
          }
          el.value = newValue;
          this.multiplyQuantityBeforeSubmit(element, fieldName, multiplier);
          el.dispatchEvent(new Event('input'));
          totalOfOrderTarget.dispatchEvent(new Event('change'));
        }
      });
      this.multiplyQuantityBeforeSubmit(element, fieldName, multiplier);
    });
  }

  fillAndSubmitForm() {
    this.formTarget.submit();
  }

  multiplyQuantityBeforeSubmit(element, fieldName, multiplier) {
    if (element.tagName.toLowerCase() === "input") {
      const el = this.formTarget.querySelector(`[name="${fieldName}"]`);
      el.value = el.value * multiplier;
    }
  }

  totalQuantityFormatter(cell, formatterParams, onRendered) {
    const totalQuantity = cell.getValue();
    const cellElement = document.createElement("div");
    cellElement.classList.add("flex", "justify-end", "items-center", "space-x-2");
    const icon = document.createElement("img");
    icon.src = "/assets/calendar.svg";
    icon.alt = "Calendar";
    icon.classList.add("mr-2", "w-4", "h-4", "tooltip");
    icon.setAttribute("title", `${cell.getRow().getData().total_expired_stock_message}`);
    cellElement.appendChild(icon);
    const quantitySpan = document.createElement("span");
    quantitySpan.textContent = totalQuantity;
    cellElement.appendChild(quantitySpan);

    return cellElement;
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
