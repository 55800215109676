import { Controller } from "@hotwired/stimulus";
import Tabulator from "../../extensions/TabulatorExtended";
import { greenLeftArrowSvg, redRightArrowSvg } from "../../helpers/constants";

// Connects to data-controller="tabulator--stock-transactions"
export default class extends Controller {
  static values = {
    url: String,
    columns: Array,
    locale: String,
  };

  connect() {
    this.tabulator = new Tabulator(this.element, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      pagination: true,
      paginationMode: "remote",
      paginationSize: 15,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: "remote",
      columns: this.processedColumns(this.columnsValue),
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
      ajaxRequesting: (url, params) => {
        this.processedSorters(params);
        return true;
      },
    });
  }

  movementTypeFormatter(cell) {
    const { value: type, display: text } = cell.getValue();
    
    let badgeColor;
  
    switch (type) {
      case 'entry':
        badgeColor = 'bg-atlassian-G300';
        break;
      case 'incomplete':
        badgeColor = 'bg-atlassian-Y300';
        break;
      case 'damaged':
      case 'transfer_damaged':
        badgeColor = 'bg-atlassian-R300';
        break;
      case 'stocktracking':
        badgeColor = 'bg-atlassian-B300';
        break;
      case 'transfer_entry':
      case 'import':
        badgeColor = 'bg-atlassian-P300';
        break;
      case 'usage':
      case 'auto_usage':
        badgeColor = 'bg-atlassian-N100';
        break;
    }
  
    const div = document.createElement('div');
    div.className = `flex badge ${badgeColor} text-white w-full`;
    div.textContent = text;
  
    return div;
  }
  

  processedColumns(columns) {
    const formatterMap = {
      movement_type: this.movementTypeFormatter.bind(this),
      arrow_icon: this.arrowIconFormatter.bind(this),
    };

    return columns.map((column) => {
      if (formatterMap[column.field]) {
        return {
          ...column,
          formatter: formatterMap[column.field],
        };
      }
      return column;
    });
  }

  processedSorters(params) {
    if (params.sort.length > 0) {
      const fieldMapping = {
        unit: "string_translations.value",
        warehouse: "warehouses.name",
        transferred_from: "transferred_froms_warehouse_stocks.name",
      };

      const sorter = params.sort[0];
      if (fieldMapping[sorter.field]) {
        sorter.field = fieldMapping[sorter.field];
      }
    }
  }

  arrowIconFormatter(cell) {
    const iconName = cell.getValue();
    const cellElement = document.createElement("div");
    cellElement.classList.add("flex", "items-center", "space-x-2");

    const icons = {
      green_left_arrow: greenLeftArrowSvg,
      red_right_arrow: redRightArrowSvg,
    };

    const icon = document.createElement("div");
    icon.innerHTML = icons[iconName] || '';
    icon.classList.add("w-5", "h-5");

    cellElement.appendChild(icon);
    return cellElement;
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
