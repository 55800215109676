import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["row", "count"];

  connect() {
    if (this.hasCountTarget) {
      this.updateVisibleRowCount();
    }
  }

  filter(event) {
    const query = event.target.value.toLowerCase();

    this.rowTargets.forEach((row) => {
      const text = row.textContent.toLowerCase();
      row.style.display = text.includes(query) ? "" : "none";
    });

    if (this.hasCountTarget) {
      this.updateVisibleRowCount();
    }
  }

  updateVisibleRowCount() {
    const groupCountMap = {};

    this.rowTargets.forEach((row) => {
      const groupId = row.dataset.groupId;

      if (!groupCountMap[groupId]) {
        groupCountMap[groupId] = 0;
      }

      if (row.style.display !== "none") {
        groupCountMap[groupId]++;
      }
    });

    this.countTargets.forEach((countElement) => {
      const groupId = countElement.dataset.groupId;
      countElement.textContent = `(${groupCountMap[groupId]})` || 0;
    });
  }
}
