/**
 * Updates the animation state of a target element based on the provided state.
 *
 * @param {Event} event - The click event triggered by the download button and passed this function.
 * @param {string} state - The state to set the animation to. Values are 'start' 'finish'.
 * @returns {void}
 */

export function animationState(event, state) {
  const downloadSvg = event.target.querySelector(".download-svg");
  const spinnerSvg = event.target.querySelector(".spinner-svg");
  
  if (state == 'start') {
    event.target.classList.add('pointer-events-none');
    downloadSvg.classList.add('hidden');
    spinnerSvg.classList.remove('hidden');
    spinnerSvg.classList.add('animate-spin');
  } else {
    downloadSvg.classList.remove('hidden');
    spinnerSvg.classList.add('hidden');
    spinnerSvg.classList.remove('animate-spin');
    event.target.classList.remove('pointer-events-none');
  }
}
  