import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {

  static values = {
    type: String,
    dataset: Array
  };

  initialize() {
    this.chart = null;
  }

  connect() {
    this[this.typeValue]();
  }

  generateWasteByTestGroups() {
    const data = this.datasetValue;
    const categories = data.map(group => group.test_group);
    const series = [
      {
        name: this.element.dataset.rerun,
        data: data.map(group => group.distribution.rerun_count)
      },
      {
        name: this.element.dataset.exception,
        data: data.map(group => group.distribution.exception_loss)
      },
      {
        name: this.element.dataset.control,
        data: data.map(group => group.distribution.control_use)
      },
      {
        name: this.element.dataset.calibrator,
        data: data.map(group => group.distribution.calibrator_use)
      },
      {
        name: this.element.dataset.extra,
        data: data.map(group => group.distribution.extra_waste)
      }
    ];
    
    const options = {
      chart: {
        type: 'bar',
        height: 450,
        stacked: true, 
        toolbar: {
          show: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '50%',
        },
      },
      series: series,
      xaxis: {
        categories: categories,
        labels: {
          formatter: function(val) {
            return val.toFixed(2) + ' %';
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      annotations: {
        xaxis: [
          {
            x: "x",
            strokeDashArray: 4,
            borderColor: '#ff7800',
            opacity: 1
          }
        ]
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function(val) {
            return val;
          }
        },
        y: {
          formatter: function(val) {
            return val.toFixed(1) + " %";
          }
        }
      },
      legend: {
        position: 'bottom'
      },
      fill: {
        opacity: 1
      }
    };

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  generatePeriodicWaste() {
    const data = this.datasetValue;
    const categories = data.map(group => group.month);
    const series = [
      {
        name: this.element.dataset.rerun,
        data: data.map(group => group.distribution.rerun_count)
      },
      {
        name: this.element.dataset.exception,
        data: data.map(group => group.distribution.exception_loss)
      },
      {
        name: this.element.dataset.control,
        data: data.map(group => group.distribution.control_use)
      },
      {
        name: this.element.dataset.calibrator,
        data: data.map(group => group.distribution.calibrator_use)
      },
      {
        name: this.element.dataset.extra,
        data: data.map(group => group.distribution.extra_waste)
      }
    ];

    const options = {
      chart: {
        type: 'line',
        height: 450,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      series: series,
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          formatter: function(val) {
            return val.toFixed(2) + ' %';
          }
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val.toFixed(2) + ' %';
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'bottom'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
    };

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  generateWasteByLabForSelectedTest() {
    const data = this.datasetValue;
    const categories = data.map(group => group.lab_name);
    const series = [
      {
        name: this.element.dataset.rerun,
        data: data.map(group => group.distribution.rerun_count)
      },
      {
        name: this.element.dataset.exception,
        data: data.map(group => group.distribution.exception_loss)
      },
      {
        name: this.element.dataset.control,
        data: data.map(group => group.distribution.control_use)
      },
      {
        name: this.element.dataset.calibrator,
        data: data.map(group => group.distribution.calibrator_use)
      },
      {
        name: this.element.dataset.extra,
        data: data.map(group => group.distribution.extra_waste)
      }
    ];
    
    const options = {
      chart: {
        type: 'bar',
        height: 450,
        stacked: true, 
        toolbar: {
          show: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '50%',
        },
      },
      series: series,
      xaxis: {
        categories: categories,
        labels: {
          formatter: function(val) {
            return val.toFixed(2) + ' %';
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      annotations: {
        xaxis: [
          {
            x: "x",
            strokeDashArray: 4,
            borderColor: '#ff7800',
            opacity: 1
          }
        ]
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function(val) {
            return val;
          }
        },
        y: {
          formatter: function(val) {
            return val.toFixed(1) + " %";
          }
        }
      },
      legend: {
        position: 'bottom'
      },
      fill: {
        opacity: 1
      }
    };

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  generatePeriodicWasteForSelectedTest() {
    const data = this.datasetValue;
    const categories = data.map(group => group.month);
    const series = [
      {
        name: this.element.dataset.totalWaste,
        data: data.map(group => group.total_waste_rate)
      },
      {
        name: this.element.dataset.extra,
        data: data.map(group => group.extra_waste)
      }
    ];

    const options = {
      chart: {
        type: 'line',
        height: 450,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      series: series,
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          formatter: function(val) {
            return val.toFixed(2) + ' %';
          }
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val.toFixed(2) + ' %';
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'bottom'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
    };

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy(); 
      this.chart = null;
    }
  }
}
