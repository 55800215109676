// TODO:: Remove completely after the stock destruction and return page 
// is implemented with turbo "stock_movements/detail_form.html.erb" https://dev.azure.com/kekik/ivdIQ/_workitems/edit/4727/
// This is used on suppliers orders too.

import {Controller} from "@hotwired/stimulus";
// import TomSelect from "tom-select";

// Connects to data-controller="stock-detail"
export default class extends Controller {
  static values = {
    formType: String
  }

  static targets = [
    "stockCard",
    "unit",
    "expireDateInput",
    "barcodeNo",
  ];

  connect() {
    this.handleRenderStockEntryAndObservation();
  }

  handleRenderStockEntryAndObservation() {
    if (typeof this.stockCardTarget.tomselect === "undefined") {
      setTimeout(() => {
        this.handleRenderStockEntryAndObservation();
      }, 100);
    } else {
      this.updateFields();
    }
  }

  updateFields() {
    let selectedCardId = this.stockCardTarget.tomselect.getValue();
    let unitInput = this.unitTarget.tomselect;

    if (selectedCardId === "") {
      unitInput.clear();
      unitInput.clearOptions();
      this.barcodeNoTarget.value = "-";
    } else {
      let warehouseId = document.getElementById("warehouse-input")?.value;

      fetch(
        "/warehouse_stock_detail?stock_card_id=" +
        selectedCardId +
        "&warehouse_id=" +
        warehouseId,
      )
        .then((response) => response.json())
        .then((data) => this.updateInput(data));

      const event = new CustomEvent('unitOptionsUpdated', {bubbles: true});
      this.element.dispatchEvent(event);
    }
  }

  updateInput(data) {
    let unitInput = this.unitTarget.tomselect;

    // TODO:: Turbo with https://dev.azure.com/kekik/ivdIQ/_workitems/edit/6376

    this.refreshSelectOptions(unitInput, data["stock_card_units"]);

    const purchaseUnit = data["stock_card_units"][0];
    if (purchaseUnit) {
      unitInput.setValue(purchaseUnit.value);
    }

    if (this.hasBarcodeNoTarget == true) {
      this.barcodeNoTarget.value = data["barcode_no"];
    }
  }

  refreshSelectOptions(input, data) {
    input.clear();
    input.clearOptions();
    input.addOptions(data);
    input.refreshItems();
  }
}
