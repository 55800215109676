import { getParamValueFromUrl } from './get_param_value_from_url';
/**
 * Focuses on the target elements that match the specified identifier or contain '_0_' in their id.
 * @param {object} eventDetail - The event detail object.
 * @param {Array} targets - The array of target elements.
 * @returns {void}
 */
export function focusNestedFormTargetElement(eventDetail, targets) {
  const identifierValue = getParamValueFromUrl(eventDetail.request.url, 'form_index');
  targets.forEach((element) => {
    if (element.id.includes(identifierValue) || element.id.includes('_0_')) {
        element.focus();
    }
  });
}

// Example usage:
// import { focusNestedFormTargetElement } from '../helpers/focus_nested_form_target_element';

// Focus on given targets when turbo_stream end (or event is triggered)
// document.addEventListener('focus_demandQuantityTargets',
//    (event) => focusNestedFormTargetElement(event.detail, this.stockDetailQuantityTargets));
