Turbo.StreamActions.update_with_selected_options = function () {
  this.targetElements.forEach((target) => {
    target.tomselect.clear();
    target.tomselect.clearOptions();
    target.replaceChildren(...this.templateContent.children);
    target.tomselect.refreshOptions();
    target.tomselect.sync();

    const options = Array.from(this.templateContent.children);
    const selectedValue = options.find(option => option.selected)?.value;
    if (selectedValue) {
      target.tomselect.setValue(selectedValue);
    } else if (options.length === 1) {
      target.tomselect.setValue(options[0].value);
    } else {
      target.tomselect.setValue("");
    }
  });
};