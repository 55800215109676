import {Controller} from "@hotwired/stimulus"
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request";
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper";

// Connects to data-controller="stock-movement-detail"
// This controller responsible for stock-movement-details form.
export default class extends Controller {

  static targets = [
    "stockCard",
    "expireDateInput",
    "lotInput",
    "lotAndExpireDateInput",
    "lotTracking"
  ];

  connect() {
    super.connect();
    if (typeof this.stockCardTarget.tomselect === "undefined") {
      setTimeout(() => {
        if (this.stockCardTarget.value !== "") {
          this.lotAndExpireDateInputTarget.dispatchEvent(new Event('change'));
        }
      }, 100);
    }
  }

  updateLotAndExpire() {
    let lotAndExpireDate = this.lotAndExpireDateInputTarget.value.split(" - ");
    let lot = lotAndExpireDate[0];
    let expireDate = lotAndExpireDate[1];
    if (lot !== "" && expireDate !== "") {
      this.lotInputTarget.value = lot;
      this.expireDateInputTarget.value = expireDate;
    }
  }

  displayTotalStock(event) {
    const stockCardId = this.stockCardTarget.tomselect.getValue();
    const lotAndExpireDate = this.lotAndExpireDateInputTarget.value.split(" - ");
    const warehouseId = event.params['warehouseId'];

    const lot = lotAndExpireDate[0];
    const expireDate = lotAndExpireDate[1];

    const turboParams = JSON.parse(event.target.dataset.turboParamsForDisplayTotalStock);

    const params = {id: stockCardId, warehouse_id: warehouseId, ...turboParams};

    if (lot !== '') {
      params.lot = lot;
    }

    if (expireDate !== '') {
      params.expire_date = expireDate;
    }

    delete params.url;
    if (params.id !== '') {
      const url = buildUrl(turboParams.url, params);
      const request = buildRequest(url, buildHeaders());
      sendTurboStreamRequest(request);
    }
  }
}
