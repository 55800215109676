import { Controller } from "@hotwired/stimulus";
import { focusNestedFormTargetElement } from "../helpers/focus_nested_form_target_element";

// Connects to data-controller="stock-movement"
export default class extends Controller {

  static targets = [ 
    'stockDetailQuantity',
  ];

  connect() {
    document.addEventListener('focus_stockDetailQuantityTargets',
      (event) => focusNestedFormTargetElement(event.detail, this.stockDetailQuantityTargets));
  }
}
