import { Controller } from "@hotwired/stimulus"
import Tabulator from "../../extensions/TabulatorExtended";

// Connects to data-controller="tabulator--customers"
export default class extends Controller {
  static values = {
    url: String,
    columns: Array,
    locale: String
  };

  connect() {
    const columns = this.prepareColumns(this.columnsValue);
    this.tabulator = new Tabulator(this.element, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      pagination: true,
      paginationMode: 'remote',
      paginationSize: 15,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: 'remote',
      columns: columns,
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
      ajaxRequesting: (url, params) => {
        this.processedSorters(params);
        return true;
      }
    });
  }

  processedSorters(params) {
    if (params.sort.length > 0) {
      const fieldMapping = {
        title: "customers.title",           
        name: "customers.name",             
        group: "client_group.name", 
        address: "customers.address",           
        phone: "customers.phone",         
        tax_office: "customers.tax_office", 
        tax_number: "customers.tax_number",
      };

      const sorter = params.sort[0];
      if (fieldMapping[sorter.field]) {
        sorter.field = fieldMapping[sorter.field];
      }
    }
  }

  prepareColumns(columns) {
    return columns;
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}