import { Controller } from "@hotwired/stimulus";
import tippy, { followCursor, roundArrow } from "tippy.js";

const tippyPlugins = { followCursor, roundArrow };

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = {
    tip: String,
    placement: String,
    tooltipTheme: String,
    tooltipDelay: String,
    tooltipDuration: String
  };

  connect() {
    const data = this.dataValues();

    const config = {
      allowHTML: true,
      plugins: [],
      content: data.tip,
      arrow: tippyPlugins.roundArrow,
      animation: "shift-away",
      zIndex: 10003,
    };

    if (data.placement) config.placement = data.placement;
    if (data.tooltipTheme) config.theme = data.tooltipTheme;
    if (data.tooltipDelay) config.delay = parseInt(data.tooltipDelay);
    if (data.tooltipDuration) config.duration = parseInt(data.tooltipDuration);

    this.tooltipInstance = tippy(this.element, config);
  }

  disconnect() {
    if (this.tooltipInstance) {
      this.tooltipInstance.destroy();
    }
  }

  dataValues() {
    return {
      tip: this.tipValue,
      placement: this.placementValue,
      tooltipTheme: this.tooltipThemeValue,
      tooltipDelay: this.tooltipDelayValue,
      tooltipDuration: this.tooltipDurationValue,
    };
  }
}
