import {Application} from "@hotwired/stimulus"
import "../stream_actions/update_input_value"
import "../stream_actions/update_select_options"
import "../stream_actions/update_with_selected_options"
import "../stream_actions/disable_selectable_options"
import "../stream_actions/enable_selectable_options"
import "../stream_actions/update_check_box_checked"
import "../stream_actions/update_input_disabled"
import "../stream_actions/select_option"

const application = Application.start()

// Configure Stimulus development experience
window.Stimulus = application
application.debug = true
export {application}