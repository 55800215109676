import { Controller } from "stimulus"
import { sendTurboStreamRequest } from "../helpers/turbo_stream_helper"
import { buildHeaders, buildRequest, buildUrl } from "../helpers/build_request"

// Connects to data-controller="leave"
export default class extends Controller {
  static targets = [
    "timeToggle",
    "endDateDiv",
    "timeSelectDiv",
    "endDate",
    "startTime",
    "endTime",
    "status",
    "user",
    "leaveTypeSelect",
    "leaveTypeSelectWrapper",
    "hourlyLeaveTypeWrapper"
  ]

  connect() {
    if (typeof this.endDateTarget._flatpickr === "undefined" && typeof this.leaveTypeSelectTarget.tomselect === 'undefined') {
      setTimeout(() => {
        this.connect();
      }, 100);
    } else {
      this.toggleVisibility();
    }

    this.userTarget.dispatchEvent(new Event('change'));
  }

  toggle() {
    this.toggleVisibility();
  }

  toggleVisibility() {
    const viewOnly = this.data.get("viewOnly") === "true";
  
    if (this.timeToggleTarget.checked) {
      this.endDateDivTarget.classList.add("hidden");
      this.timeSelectDivTarget.classList.remove("hidden");
      this.leaveTypeSelectWrapperTarget.classList.add("hidden");
      this.hourlyLeaveTypeWrapperTarget.classList.remove("hidden");
      this.leaveTypeSelectTarget.tomselect.disable();
  
      this.disable([this.endDateTarget]);
      if (!viewOnly) {
        this.enable([this.startTimeTarget, this.endTimeTarget]);
      } else {
        this.disable([this.startTimeTarget, this.endTimeTarget]);
      }
    } else {
      this.endDateDivTarget.classList.remove("hidden");
      this.timeSelectDivTarget.classList.add("hidden");
      this.leaveTypeSelectWrapperTarget.classList.remove("hidden");
      this.hourlyLeaveTypeWrapperTarget.classList.add("hidden");
      
      if (!viewOnly) {
        this.leaveTypeSelectTarget.tomselect.enable();
        this.enable([this.endDateTarget]);
      } else {
        this.leaveTypeSelectTarget.tomselect.disable();
        this.disable([this.endDateTarget]);
      }
      
      this.disable([this.startTimeTarget, this.endTimeTarget]);
    }
  }

  disable(targets) {
    targets.forEach(target => {
      target._flatpickr.input.disabled = true;
    });
  }

  enable(targets) {
    targets.forEach(target => {
      target._flatpickr.input.disabled = false;
    });
  }

  reject() {
    this.statusTarget.value = 'denied';
    this.element.submit();
  }

  approve() {
    this.statusTarget.value = 'approved';
    this.element.submit();
  }
  
  userTurboStreamRequest(event) {
    const selectBox = event.target
    const turboParams = JSON.parse(selectBox.dataset.turboParams);
    const params = { user_id: selectBox.value };
    const url = buildUrl(turboParams.url, params);
    const request = buildRequest(url, buildHeaders());
    sendTurboStreamRequest(request);
  }
}