import {Controller} from "@hotwired/stimulus"
import IMask from "imask";
// Connects to data-controller="expense"
export default class extends Controller {
  static targets = ["device", "dependentOptions", "termStart", "termEnd", "testGroup", "labGroup"];

  connect() {
    if (!this.deviceTarget.tomselect) {
      return;
    }

    this.changeDisplayOfInputs();
  }

  // Assign the expense term inputs (:term_start and :term_end).
  assignTermInput(event) {
    const termInput = event.target.value;
    if (termInput !== "") {
      const containsStartAndEnd = termInput.includes(" - ");
      if (containsStartAndEnd) {
        const dates = termInput.split(" - ");
        this.termStartTarget.value = dates[0];
        this.termEndTarget.value = dates[1];
      } else {
        this.termStartTarget.value = termInput;
      }
    } else {
      this.termStartTarget.value = '';
      this.termEndTarget.value = '';
    }
  }

  // Disable lab and test group options when device selected
  changeDisplayOfInputs() {
    const device = this.deviceTarget.tomselect.getValue();
    if (device) {
      this.dependentOptionsTargets.forEach(el => this.disable(el));
    } else {
      this.dependentOptionsTargets.forEach(el => this.enable(el));
    }
  }

  // visually disable the lab group when test group selected
  visuallyDisableLabGroupWhenTestGroupSelected() {
    const testGroup = this.testGroupTarget.tomselect.getValue();
    const labGroupWrapper = this.labGroupTarget.closest('.ts-wrapper');
    
    if(testGroup) {
      this.disable(labGroupWrapper);
    } else {
      this.enable(labGroupWrapper);
    }
  }

  enable(input) {
    input.classList.remove("pointer-events-none");
    const tsControl = input.querySelector('.ts-wrapper .ts-control');
    if (tsControl) {
      tsControl.style.backgroundColor = "";
    }
  }

  disable(target) {
    target.classList.add("pointer-events-none");
    const tsControl = target.querySelector('.ts-wrapper .ts-control');
    if (tsControl) {
      tsControl.style.backgroundColor = "#F4F4F5";
    }
  }
}
